import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { TestCaseOverview } from './testCaseOverview'


import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { SessionService } from '../commenServices/SessionService';
import { Router } from '@angular/router';
import { ServiceSearchService } from '../commenServices/search.service';
import { ValuesOftheStringGoup } from '../domainModel/FacetsForStringType';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BusinessService } from "../domainModel/BusinessService";
import { User } from '../domainModel/User';
import { PublicApiService } from '../commenServices/public.api.service';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public testcases: TestCaseOverview;
  public observers: TestCaseOverview;
  public name: string;
  public selectedPizzas: ValuesOftheStringGoup[];
  public tags: ValuesOftheStringGoup[];
  public categories: ValuesOftheStringGoup[];
  public services: BusinessService[];
  public amountOfServices: number;
  public products: ValuesOftheStringGoup[];
  public selectedCategories: ValuesOftheStringGoup[];
  public selectedProducts: ValuesOftheStringGoup[];
  public servicesReviewedByUser: BusinessService[];
  public curUser: User;
  companyOfUser: string;
  companyName: string;
  modalRef: MDBModalRef;


  @ViewChild('frameMyServices') public modal: any;
  validatingForm: FormGroup;
  closeModal: string;


  public gotoPublish() {
    this.router.navigate(['/publish']);
  }

  public gotoSearch() {
    this.router.navigate(['/search']);
  }

  public gotToReviewProcess() {
    this.router.navigate(['/reviewProcess']);
  }

  login(content) {


    this.modalService.dismissAll();


  }

  close(content) {

  }






  constructor(private apiService: PublicApiService, private serviceSearch: ServiceSearchService, private router: Router, private modalService: NgbModal, private modalService2: MDBModalService, private sessionService: SessionService, private dialog: MatDialog) {
    this.validatingForm = new FormGroup({
      loginFormModalEmail: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      loginFormModalPassword: new FormControl('', Validators.required)
    });
  }




  openStartDialog(x: BusinessService) {
    const dialogRef = this.dialog.open(DialogStart, { data: { router: this.router, service: x, email: this.sessionService.emailOfUser, searchService: this.serviceSearch } });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    })
  }



  underReview(x: BusinessService): boolean {
    let statusOfBusiness = (x.deployment.status == 'UNDER_REVIEW');
    let emailMatches;
    return statusOfBusiness
  }

  ngAfterViewInit(): void {
    this.getNewServicesToReview();
    let status: boolean;
    status = this.sessionService.isLoggedIn
    if (status == false) {
      this.modal.disableClose = true;
      this.modal.show();
    } else {
      this.serviceSearch.searchForAllBusinessServicesTobeReviewed().subscribe(
        (response) => {
          this.services = response.trickServices;
          if (response.trickServices != null) {
            this.amountOfServices = response.trickServices.length;
          }
          else {
            this.amountOfServices = 0;
          }
        },
        (error) => {
          console.error('Request failed');
        }
      )
    }



  }

  public getNewServicesToReview(): void {
    this.serviceSearch.searchForAllBusinessServicesTobeReviewed().subscribe(
      (response) => {
        console.log("response received");
        this.services = response.trickServices;
        if (response.trickServices != null) {
          this.amountOfServices = response.trickServices.length;
        }
        else {
          this.amountOfServices = 0;
        }
      },
      (error) => {
        console.error('Request failed');
      }
    )
  }

  onNgModelChange(event) {
  }

  ngOnInit() {
    localStorage.clear();
    this.getNewServicesToReview();
    if (this.sessionService.isLoggedIn) {
      this.curUser = this.sessionService.curUser;
      this.serviceSearch.searchForAllBusinessServicesTobeReviewed().subscribe(
        (response) => {
          this.services = response.trickServices;
          if (response.trickServices != null) {
            this.amountOfServices = response.trickServices.length;
          }
          else {
            this.amountOfServices = 0;
          }
        },
        (error) => {
          console.error('Request failed');
        }

      )
    } else {
      this.modal.show()
    }




  }


  success() {
    if (this.loginFormModalEmail.value.length > 0) {
      if ((this.loginFormModalEmail.value.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")) && (this.loginFormModalPassword.value.length > 0)) {
        this.sessionService.companyOfUser = this.loginFormModalEmail.value;
        return true;
      }
      else {
        return false;
      }
    }
    return false;
  }

  goToDashBoard() {
    this.modal.hide();
    this.router.navigate(['/dashboard']);
  }
  get loginFormModalEmail() {
    return this.validatingForm.get('loginFormModalEmail');
  }

  get loginFormModalPassword() {
    return this.validatingForm.get('loginFormModalPassword');
  }

  isLoggedIn() {
    if (this.sessionService.isLoggedIn == true) {
      return true;
    }
    else {
      return false;
    }
  }

  confirmLogin() {

    this.apiService.login(this.validatingForm.get('loginFormModalEmail').value, this.validatingForm.get('loginFormModalPassword').value).subscribe(

      (response) => {
        let token = JSON.stringify(response);
        if (token.length > 10) {

          this.sessionService.isLoggedIn = true;
          this.sessionService.oAuthToken = response.token;
          this.sessionService.emailOfUser = this.loginFormModalEmail.value;
          this.sessionService.companyOfUser = this.companyName;


          this.apiService.getUserDetails(this.sessionService.emailOfUser).subscribe(

            (response) => {
              this.curUser = response;
              this.sessionService.curUser = this.curUser;
              this.companyOfUser = this.curUser.email;;
              this.sessionService.curUser = this.curUser;

              this.serviceSearch.searchForAllBusinessServicesTobeReviewed().subscribe(
                (response) => {
                  this.services = response.trickServices;
                  if (response.trickServices != null) {
                    this.amountOfServices = response.trickServices.length;
                  }
                  else {
                    this.amountOfServices = 0;
                  }
                },
                (error) => {
                  console.error("Request failed")
                });

            });
          this.modal.hide();
        } else {

          console.log("Sorry, your credentials are wrong or the srvice is not reachable");
        }
      }, exception => { alert("Wrong credentials") }
    );

  }
}

@Component({
  selector: 'dialog-start',
  templateUrl: 'startProcess.html',
  styleUrls: ['./dialog.css']
})
export class DialogStart {
  private router: Router;
  private service: BusinessService;
  private email: string;
  private search: ServiceSearchService;
  constructor(public dialogRef: MatDialogRef<DialogStart>,

    @Inject(MAT_DIALOG_DATA) public data: { router: Router, service: BusinessService, email: string, searchService: ServiceSearchService }) {
    this.router = this.data.router;
    this.service = this.data.service;
    this.email = this.data.email;
    this.search = this.data.searchService;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  onOk(): void {

    this.dialogRef.close();
  }
  public gotToReviewProcess() {
    this.dialogRef.close();
    localStorage.setItem("name", this.service.basic_infos.name);
    localStorage.setItem("dockerComposer", this.service.deployment.dockerDescription);
    localStorage.setItem("dockerTag", this.service.deployment.dockerTag);
    localStorage.setItem("name", this.service.basic_infos.name);
    localStorage.setItem("service", JSON.stringify(this.service));
    this.service.deployment.reviewStartedBy = this.email;


    this.router.navigate(['/review-stepper']);
  }
}
