<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <title>End Review Process</title>
</head>

<body>
  <p>Are you sure that you want to end the review of Test?</p>
  <br>
  <p>If yes, the owner of the service will be notified by your decision</p>
  <br>
  <div>
    <button class="dashButton" (click)="onNoClick()"> Cancel</button>
    <button class="dashButton" (click)="goBackToDashboard()"> Finish Review</button>
  </div>
</body>

</html>