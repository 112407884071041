<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <title>Start Review Process</title>
</head>

<body>
  <p>Are you sure that you want to start the review of {{this.data.service.basic_infos.name}}?</p>
  <br>
  <p>If yes, it's status on the marketplace will bet set to UNDER REVIEW.</p>
  <br>
  <div>
    <button class="dashButton" (click)="onNoClick()"> Cancel</button>
    <button class="dashButton" (click)="gotToReviewProcess()"> Start</button>
  </div>
</body>

</html>