<div class="main-content">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <h2></h2>
        <h2> {{this.name}} Review Process</h2>
        <div fxLayout="Row">
          <label class="form-check-label" style="margin-left: 10%;">
            <input [(ngModel)]="serviceDetail" required class="form-check-input" id="composer" type="checkbox" value="">
            <label class="smalllabel">I manually validated the Service Details</label>
            <span class="form-check-sign"></span>
          </label>
        </div>
        <div fxLayout="Row">
          <label class="form-check-label" style="margin-left: 10%;">
            <input [(ngModel)]="validComposer" required class="form-check-input" id="composer" type="checkbox" value="">
            <label class="smalllabel">I manually validated the Docker Composer File</label>
            <span class="form-check-sign"></span>
          </label>
        </div>
        <div>
          <label class="form-check-label" style="margin-left: 10%;">
            <input [(ngModel)]="validTag" required class="form-check-input" id="information" type="checkbox" value="">
            <label class="smalllabel">I manually validated the Docker Information Tag</label>
            <span class="form-check-sign"></span>
          </label>
        </div>
        <div>

          <label class="label"> Choosen Status</label>
          <select [(ngModel)]="status" class="statusSelect" name="statusSelect" required>
            <option value="">Please select a Status</option>
            <option value="accepted">Accept</option>
            <option value="denied">Deny</option>
          </select>

        </div>
        <div>
          <label class="label">Explanation:</label>
          <textarea [(ngModel)]="explanation" class="explanation"></textarea>
        </div>
        <button mat-button matStepperPrevious class="dashButton" (click)="goToPrev()">Prev</button>
        <button mat-button [disabled]="!unableButton" class="dashButton" (click)="openFinishDialog()"> Finish</button>
      </div>
    </div>
  </div>
</div>