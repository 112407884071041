<html>

<body>


  <section class="breadcrum_area w-100 d-flex justify-content-center align-items-center">
    <!-- <div class="panel-header panel-header-lg"> -->
    <div class="panel-header-lg">
      <div class="header text-center headerImg">
        <h2 class="title-style">Services to review</h2>

        <!--<img src="./assets/img/TRick-logo.png" style="width: 20vw; min-width: 330px;"/>-->
      </div>

    </div>
  </section>


  <div class="main-content">
    <div class="col-md-12 mainBlock">
      <div class="card">
        <div class="card-body">
          <div class="col-md-12">
          </div>
          <div *ngIf="isLoggedIn()">
            <h4 class="card-title">Business Services to review: {{amountOfServices}}</h4>
            <h6>Currently logged in as: {{curUser.firstName}} {{curUser.lastName}}</h6>
            <div>
              <button class="dashButton" (click)="getNewServicesToReview()">Get new services to review</button>
            </div>
          </div>
          <table class="table">
            <thead class="text-primary">
              <tr>
                <td>Services</td>
                <td>Status</td>
                <td> Action</td>

              </tr>
            </thead>
            <tbody class="table">
              <tr *ngFor="let x of this.services">
                <td>
                  {{x.basic_infos.name}}
                </td>
                <td>
                  {{x.deployment.status}}
                </td>
                <td>
                  <button *ngIf="!(underReview(x))" class="dashButton" (click)="openStartDialog(x)"> START</button>
                  <button *ngIf="(underReview(x))" class="dashButton" (click)="openStartDialog(x)"> Continue</button>

                </td>
              </tr>
            </tbody>
          </table>


        </div>
      </div>

      <div mdbModal #frameMyServices="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
        aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h4 class="modal-title w-100 font-weight-bold">Sign in with your e-mail</h4>

            </div>
            <div class="modal-body mx-3">

              <div class="md-form mb-5">
                <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
                <input type="email" id="defaultForm-emailMyServices" [formControl]="loginFormModalEmail"
                  class="form-control" mdbInput mdbValidate>
                <label for="defaultForm-emailMyServices">Your company e-mail</label>
                <mdb-error
                  *ngIf="loginFormModalEmail.invalid && (loginFormModalEmail.dirty || loginFormModalEmail.touched)">
                  Input invalid
                </mdb-error>
                <mdb-success
                  *ngIf="loginFormModalEmail.valid && (loginFormModalEmail.dirty || loginFormModalEmail.touched)">
                  Input
                  valid
                </mdb-success>
              </div>
              <div class="md-form mb-4">
                <mdb-icon fas icon="lock" class="prefix grey-text"></mdb-icon>
                <input type="password" id="defaultForm-pass" [formControl]="loginFormModalPassword" class="form-control"
                  mdbInput mdbValidate>
                <label for="defaultForm-pass">Your password</label>
                <mdb-error
                  *ngIf="loginFormModalPassword.invalid && (loginFormModalPassword.dirty || loginFormModalPassword.touched)">
                  Input invalid
                </mdb-error>
                <mdb-success
                  *ngIf="loginFormModalPassword.valid && (loginFormModalPassword.dirty || loginFormModalPassword.touched)">
                  Input valid
                </mdb-success>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <button mdbBtn color="default" class="waves-light" *ngIf="success()" (click)="confirmLogin()"
                mdbWavesEffect>Login</button>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <button mdbBtn color="default" class="waves-light" (click)="goToDashBoard()"
                mdbWavesEffect>Cancel</button>
            </div>
          </div>
        </div>
      </div>


    </div>

  </div>