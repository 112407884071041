<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <title>Pause Review Process</title>
</head>

<body>
  <p>Are you sure that you want to pause the review of Test?</p>
  <br>
  <p>If yes, the owner of the service won't be notified of this decision or the current status of the review process</p>
  <br>
  <div>
    <button class="dashButton" (click)="onNoClick()"> Cancel</button>
    <button class="dashButton" (click)="onOk()"> Stop Review</button>
  </div>
</body>

</html>