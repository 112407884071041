// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  searchServiceRootURL: window["env"]["searchServiceRootURL"] || "default",
  apiServiceRootURL: window["env"]["apiServiceRootURL"] || "default",
  notificationServiceRootURL: window["env"]["notificationServiceRootURL"] || "default",
  deploymentServiceRootURL: window["env"]["deploymentServiceRootURL"] || "default",
  debug: window["env"]["debug"] || false,

  //apiServiceRootURL: 'https://www.trick.ikap.biba.uni-bremen.de/public-api-service/invoke/',
  //searchServiceRootURL: 'https://www.trick.ikap.biba.uni-bremen.de/service-management-service/',
  searchForLabelsAPI: 'searchForLabels',
  getAllServicesAPI: 'searchForAllBusinessServices',
  getFacetsForPriceAPI: 'getFacettedForPrice',
  searchForServicesWithFacettesAPI: 'searchForAllBusinessServicesAccordingToFacettes',
  getFacettedForCategoryAPI: 'getFacettedForCategory',
  getFacettedForOwnerAPI: 'getFacettedForOwner',
  getFacettedForPriceModusAPI: 'getFacettedForPriceModus',
  getFacettedForTagsAPI: 'getFacettedForTags',
  getFacettedForPoductsAPI: 'getFacettedForApplicableProducts',
  delete: 'delete',
  update: 'update',
  updateServiceDirectForStars: 'updateServiceDirectForStars',
  checkNameOfService: 'checkNameOfService',
  searchForAllBusinessServicesYouOwn: 'searchForAllBusinessServicesYouOwn',
  isValidDockerDesc: 'isValidDockerDesc',
  //deploymentServiceRootURL: 'https://www.trick.ikap.biba.uni-bremen.de/deployment-service/'
};
