import { Injectable } from '@angular/core';
import { ISession } from "./ISession";
import { BusinessService } from "../domainModel/BusinessService";
import { User } from '../domainModel/User';

@Injectable({ providedIn: 'root', })
export class SessionService implements ISession {
  private _session: BusinessService;
  private loggedIn: boolean;
  private _companyOfUser: string;
  private _emailOfUser: string;
  private _user: User;
  private _oAuth: string;
  curUser: User;

  constructor() {
    this.loggedIn = false;
  }

  set session(value) {
    this._session = value;
  }

  get session() {
    return this._session
  }

  get isLoggedIn() {
    return this.loggedIn;
  }

  set isLoggedIn(value) {
    this.loggedIn = value;
  }

  get companyOfUser() {
    return this._companyOfUser;
  }
  set companyOfUser(value) {
    this._companyOfUser = value;
  }

  get emailOfUser() {
    return this._emailOfUser;
  }
  set emailOfUser(value) {
    this._emailOfUser = value;
  }
  get oAuthToken() {
    return this._oAuth;
  }
  set oAuthToken(value) {
    this._oAuth = value;
  }
}
