import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription, timer } from "rxjs";
import { map } from "rxjs/operators";
import { BusinessService } from "../domainModel/BusinessService";
import { BusinessServiceStatus } from "../domainModel/BusinessServiceStatus";
import { ServiceSearchService } from "../commenServices/search.service";
import { DashboardComponent } from "../dashboard/dashboard.component";

@Component({
  selector: 'app-review-02',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class Review02Component implements OnInit {
  dashboard: DashboardComponent;
  panelOpenState = false;
  unableButton: boolean = false;
  dockerTag: HTMLInputElement;
  dockerComposer: HTMLInputElement;
  status: string;
  explanation: string;
  validComposer: boolean;
  validTag: boolean;
  serviceDetail: boolean;
  subscription: Subscription;
  service: BusinessService;
  name: string;
  constructor(public dialog: MatDialog, public router: Router, private serviceSearch: ServiceSearchService) {
  }
  ngOnInit() {
    this.service = JSON.parse(localStorage.getItem("service"));
    this.name = this.service.basic_infos.name;
    this.subscription = timer(0, 500).pipe(
      map(() => {
        this.unableDisableButton();
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  unableDisableButton() {

    if (this.validComposer && this.validTag && this.serviceDetail) {
      if (this.status != undefined) {
        if (this.status === "denied") {
          if (this.explanation != undefined) {
            this.unableButton = true;
          } else {
            this.unableButton = false;
          }


        } else {
          this.unableButton = true;
        }
      }
    } else {
      this.unableButton = false;
    }

  }

  goToPrev() {
    localStorage.setItem("status", this.status);
    localStorage.setItem("explanation", this.explanation);
    localStorage.setItem("validDocker", String(this.validComposer));
    localStorage.setItem("validTag", String(this.validTag));
    localStorage.setItem("serviceDetail", String(this.serviceDetail));


  }

  openFinishDialog() {
    const dialogRef = this.dialog.open(DialogFinish, { data: { router: this.router } });
    dialogRef.afterClosed().subscribe(result => {
      let newStatus: BusinessServiceStatus

      if (this.status === "accepted") {
        this.serviceSearch.setServiceToApproved(this.service, this.explanation).subscribe(
          (response) => {

            localStorage.clear();
            this.router.navigate(['/dashboard/'])
          },
          (error) => {
            console.log(error);
            console.error("Denied");
            localStorage.clear();
            this.router.navigate(['/dashboard/'])
          }
        )
      } else {
        console.log("Service was declined");
        this.serviceSearch.setServiceToDeclined(this.service, this.explanation).subscribe(
          (response) => {
            this.service.deployment.status;
            console.log("Update was successful");
            localStorage.clear();
            this.router.navigate(['/dashboard/'])
          },
          (error) => {
            console.log(error);
            console.error("Declined");
            localStorage.clear();
            this.router.navigate(['/dashboard/'])
          }
        )
      }


    })
  }

  openSaveDialog() {
    const dialogRef = this.dialog.open(DialogSave, { data: this.router });

  }

}

@Component({
  selector: 'dialog-save',
  templateUrl: 'saveDialog.html',
  styleUrls: ['dialog.scss']
})
export class DialogSave {
  service: BusinessService;
  constructor(public dialogRef: MatDialogRef<DialogSave>,
    @Inject(MAT_DIALOG_DATA) public router: Router) {
  }
  onNoClick() {
    this.dialogRef.close()
  }
  onOk() {

    this.dialogRef.close();
    this.router.navigate(['/dashboard'])
  }
}

@Component({
  selector: 'dialog-finish',
  templateUrl: 'finishDialog.html',
  styleUrls: ['./dialog.scss']
})
export class DialogFinish {
  router: Router;
  constructor(public dialogRef: MatDialogRef<DialogFinish>,
    @Inject(MAT_DIALOG_DATA) public data: { router: Router }) {
    this.router = data.router;
  }

  onNoClick() {
    this.dialogRef.close();
  }
  goBackToDashboard() {
    this.dialogRef.close();

  }
}
